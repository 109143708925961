import React, { useState } from "react";
import axios from "../utils/axiosConfig";

function QuestionForm() {
  const [questionText, setQuestionText] = useState("");
  const [options, setOptions] = useState(["", "", "", ""]);
  const [orderQuestion, setOrderQuestion] = useState(1);
  const [correctAnswer, setCorrectAnswer] = useState("");
  const [explanation, setExplanation] = useState("");
  const [levelId, setLevelId] = useState(1);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const questionData = {
      questionText,
      options,
      orderQuestion,
      correctAnswer,
      explanation,
      levelId,
    };

    try {
      const response = await axios.post("/questions", questionData); // Sử dụng đường dẫn tương đối
      console.log("Response:", response.data);
      alert("Câu hỏi đã được thêm thành công!");
      setQuestionText("");
      setOptions(["", "", "", ""]);
      setOrderQuestion(1);
      setCorrectAnswer("");
      setExplanation("");
      setLevelId(1);
    } catch (error) {
      console.error("Error:", error);
      alert("Đã xảy ra lỗi khi thêm câu hỏi.");
    }
  };

  const handleOptionChange = (index, value) => {
    const newOptions = [...options];
    newOptions[index] = value;
    setOptions(newOptions);
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="p-4 max-w-md mx-auto bg-white shadow-md rounded-md"
    >
      <div>
        <label>Câu hỏi:</label>
        <input
          type="text"
          value={questionText}
          onChange={(e) => setQuestionText(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div>
        <label>Tùy chọn:</label>
        {options.map((option, index) => (
          <input
            key={index}
            type="text"
            value={option}
            onChange={(e) => handleOptionChange(index, e.target.value)}
            placeholder={`Tùy chọn ${index + 1}`}
            className="w-full p-2 border rounded mt-2"
          />
        ))}
      </div>

      <div>
        <label>Thứ tự câu hỏi:</label>
        <input
          type="number"
          value={orderQuestion}
          onChange={(e) => setOrderQuestion(parseInt(e.target.value))}
          className="w-full p-2 border rounded"
        />
      </div>

      <div>
        <label>Đáp án đúng:</label>
        <input
          type="text"
          value={correctAnswer}
          onChange={(e) => setCorrectAnswer(e.target.value)}
          className="w-full p-2 border rounded"
        />
      </div>

      <div>
        <label>Giải thích:</label>
        <textarea
          value={explanation}
          onChange={(e) => setExplanation(e.target.value)}
          className="w-full p-2 border rounded"
        ></textarea>
      </div>

      <div>
        <label>Level ID:</label>
        <input
          type="number"
          value={levelId}
          onChange={(e) => setLevelId(parseInt(e.target.value))}
          className="w-full p-2 border rounded"
        />
      </div>

      <button
        type="submit"
        className="mt-4 w-full bg-blue-500 text-white py-2 rounded"
      >
        Thêm câu hỏi
      </button>
    </form>
  );
}

export default QuestionForm;
