import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import Home from "../src/components/Home";
import QuizSelectionScreen from "../src/components/QuizSelectionScreen";
import QuizQuestions from "../src/components/quizQuestions";
import Achievements from "../src/components/Achievements";
import Complete from "../src/components/Complete";
import FormQuestion from "../src/components/FormQuestion";

function App() {
  return (
    <Router>
      <Routes>
        {/* Route đến trang bắt đầu */}
        <Route path="/" element={<Home />} />
        {/* Route đến trang chọn màn */}
        <Route path="/quiz-selection" element={<QuizSelectionScreen />} />
        <Route path="/level/:levelId" element={<QuizQuestions />} />
        <Route path="/achievements" element={<Achievements />} />
        <Route path="/complete" element={<Complete />} />
        <Route path="/form-question" element={<FormQuestion />} />
        {/* Route để chuyển hướng về trang chủ nếu không tìm thấy đường dẫn */}
        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Router>
  );
}

export default App;
