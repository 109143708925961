import axios from "../utils/axiosConfig";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";

function QuizSelectionScreen() {
  const [levels, setLevels] = useState([]); // State để lưu danh sách level từ API
  const [userProgress, setUserProgress] = useState([]); // State để lưu tiến trình người chơi từ API
  const navigate = useNavigate(); // Sử dụng hook để điều hướng

  const audioRef = useRef(null);

  useEffect(() => {
    const fetchUserProgress = async () => {
      try {
        const userId = localStorage.getItem("userId"); // Lấy userId từ localStorage hoặc nguồn khác
        const response = await axios.get(`/users/${userId}/progress`); // Gọi API lấy tiến trình người chơi
        const completedLevels = JSON.parse(response.data.completedLevels).map(
          Number
        );
        setUserProgress(completedLevels); // Lưu tiến trình vào state
      } catch (error) {
        console.error("Error fetching user progress:", error);
      }
    };

    fetchUserProgress();
  }, []);

  // Gọi API để lấy danh sách các level
  useEffect(() => {
    const fetchLevels = async () => {
      try {
        const response = await axios.get("levels"); // URL API của bạn
        setLevels(response.data); // Lưu dữ liệu trả về từ API vào state
      } catch (error) {
        console.error("Error fetching levels:", error);
      }
    };
    fetchLevels();
  }, []);

  const handleStartClick = () => {
    // Điều hướng đến trang chọn màn
    navigate("/");
  };

  // Hàm kiểm tra xem màn đã hoàn thành chưa
  const isLevelCompleted = (levelId) => {
    return userProgress.includes(levelId);
  };
  return (
    <div className="relative w-full max-w-full lg:max-w-[500px] mx-auto">
      <audio ref={audioRef} src="/images/trang_chon_man/choose.mp3" loop />
      <div className="relative h-screen bg-cover bg-[#bfe9ff] bg-center overflow-hidden">
        {/* Grid for cards */}
        <div className="grid grid-cols-4 gap-1 justify-items-center mx-5 my-[10px] z-20 h-auto">
          {levels.map((level, index) => {
            const levelCompleted = isLevelCompleted(level.id); // Kiểm tra xem màn đã hoàn thành hay chưa
            const cardImage = levelCompleted
              ? `/images/bogoc/${level.image}` // Đường dẫn ảnh màn đã hoàn thành
              : `/images/trang_chon_man/Element.png`; // Đường dẫn ảnh mặc định
            return (
              <div key={level.id} className="relative mb-2 z-10">
                {/* Card Image */}
                <img
                  src={cardImage}
                  alt={`Card ${index + 1}`}
                  className="w-[70px] mb-2"
                />
                {/* Number Image */}
              </div>
            );
          })}
        </div>

        {/* Background Image (like the grass and trees) */}
        <div className="absolute bottom-0 left-0 right-0">
          <img
            onClick={() => handleStartClick()}
            src="/images/trang_chon_man/quay_ve.png"
            alt="Ground"
            className="absolute bottom-[30px] right-[20px] transform -translate-x-1/2 w-[60px] z-[49]"
          />
          <img
            src="/images/trang_chon_man/dat.png"
            alt="Ground"
            className="w-full"
          />
        </div>
      </div>
    </div>
  );
}

export default QuizSelectionScreen;
