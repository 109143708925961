import { useState } from "react";
import { useNavigate } from "react-router-dom";

function Complete() {
  const [step, setStep] = useState(1);
  const [name, setName] = useState("");
  const [nickName, setNickName] = useState("");
  const navigate = useNavigate();
  const finish = () => {
    navigate("/");
  };

  return (
    <div className="relative w-full max-w-full lg:max-w-[430px] mx-auto">
      {step === 1 && (
        <div
          className="relative h-screen bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: "url(/images/complete/mau1.png)" }}
        >
          <button
            onClick={() => {
              setStep(2);
            }}
            className=" absolute bottom-1 right-1 w-24 h-24 z-10"
          ></button>
        </div>
      )}
      {step === 2 && (
        <div
          className="relative h-screen bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: "url(/images/complete/nen.png)" }}
        >
          <div className="relative mt-[40%]">
            <img
              src="images/complete/1.png"
              alt="Sun and Buddha"
              className="absolute top-[40px] left-1/2 transform -translate-x-1/2 w-72"
            />
            <input
              className="px-2 text-2xl font-semibold text-red-500 absolute top-[100px] bg-transparent left-1/2 transform -translate-x-1/2 w-72 h-12 focus:border-red-600 focus:outline-none border-dashed forced rounded-2xl border-2 border-indigo-600"
              value={name}
              onChange={(e) => setName(e.target.value)}
            ></input>
            <img
              src="images/complete/2.2.png"
              alt="Sun and Buddha"
              className="absolute top-[150px] left-1/2 transform -translate-x-1/2 w-72"
            />
            <input
              className="px-2 text-2xl font-semibold text-red-500 absolute top-[250px] bg-transparent left-1/2 transform -translate-x-1/2 w-72 h-12 focus:border-red-600 focus:outline-none border-dashed forced rounded-2xl border-2 border-indigo-600"
              value={nickName}
              onChange={(e) => setNickName(e.target.value)}
            ></input>
          </div>
          <button
            onClick={() => {
              setStep(3);
            }}
            className="absolute bottom-1 right-3 w-36 h-16 z-10"
          >
            <img
              src="images/complete/6.png"
              alt="Sun and Buddha"
              className=" absolute bottom-1 right-3 h-12"
            />
          </button>
        </div>
      )}
      {step === 3 && (
        <div
          className="relative h-screen bg-cover bg-center overflow-hidden"
          style={{ backgroundImage: "url(/images/complete/nen.png)" }}
        >
          <img
            src="images/complete/5.png"
            alt="Sun and Buddha"
            className="absolute w-[400] left-1/2 transform -translate-x-1/2"
          />
          <div className="relative mt-[50%]">
            <img
              src="images/complete/8.png"
              alt="Sun and Buddha"
              className="absolute w-full h-[300px] left-1/2 transform -translate-x-1/2"
            />
            <div className="absolute w-full lg:top-[155px] lg:left-[48%] top-[156px] left-[48%] transform -translate-x-1/2 flex flex-col  z-30">
              <label className="  text-[#933f3f] text-xl font-bold ml-[155px]  lg:ml-[170px]">
                {name}
              </label>
              <label className=" italic text-[#933f3f] text-xs font-semibold mt-[-2px] ml-[170px] ">
                {"(" + nickName + ")"}
              </label>
            </div>
          </div>
          <button
            onClick={() => {
              finish();
            }}
            className=" absolute bottom-1 right-3 w-36 h-16 z-10"
          >
            <img
              src="images/complete/6.png"
              alt="Sun and Buddha"
              className=" absolute bottom-1 right-3 h-12"
            />
          </button>
        </div>
      )}
    </div>
  );
}
export default Complete;
